import { Inject, Injectable } from '@angular/core'
import { Models, Query } from 'appwrite'
import { Observable, catchError, defer, from, map, of } from 'rxjs'
import { environment } from 'src/environments/environment'
import { APPWRITE_SDK, AppwriteSdk } from './appwrite.provider'
import { UserAddress, toUserAddress } from 'src/app/auth/interfaces'

@Injectable({
    providedIn: 'root',
})
export class UserAddressDocumentService {
    constructor(@Inject(APPWRITE_SDK) private _appwrite: AppwriteSdk) {}

    findUserAddressDocument(
        userID: string
    ): Observable<UserAddress | null> {
        return from(
            this._appwrite.databases.listDocuments(
                environment.main_database_id,
                environment.user_address_collection_id,
                [Query.equal('$id', userID)]
            )
        ).pipe(
            map(response => toUserAddress(response.documents[0])),
            catchError(error => {
                console.error('Error fetching user address:', error);
                return of(null);
            })
        )
    }

    updateUserAddressDocument(
        userID: string,
        address: UserAddress
    ): Observable<Models.Document> {
        return defer(() =>
            this._appwrite.databases.updateDocument(
                environment.main_database_id,
                environment.user_address_collection_id,
                userID,
                {
                    country: address.country,
                    state: address.state,
                    city: address.city,
                    street: address.street,
                    post_code: address.postCode,
                    house_number: address.houseNo,
                }
            )
        )
    }

    createUserAddressDocument(
        userID: string,
        address: UserAddress
    ): Observable<Models.Document> {
        return defer(() =>
            this._appwrite.databases.createDocument(
                environment.main_database_id,
                environment.user_address_collection_id,
                userID,
                {
                    country: address.country,
                    state: address.state,
                    city: address.city,
                    street: address.street,
                    post_code: address.postCode,
                    house_number: address.houseNo,
                }
            )
        )
    }
}
