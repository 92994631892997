import { CommonModule } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import {
    FormBuilder,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { ActivatedRoute, RouterModule } from '@angular/router'
import { Store } from '@ngrx/store'
import { TranslocoModule } from '@ngneat/transloco'
import { Observable } from 'rxjs'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { AuthActions } from '../../store/actions/auth.actions'
import { selectAuthError, selectIsSubmitting } from '../../store/selectors/auth.selectors'
import { PasswordValidators } from 'src/app/shared/validators/password.validator'
import { AppwriteException } from 'appwrite'

export const RETURN_URL_QUERY_PARAM = 'return_url'

@UntilDestroy()
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RouterModule,
        MatCardModule,
        MatFormFieldModule,
        MatIconModule,
        TranslocoModule,
        MatButtonModule,
        MatInputModule,
    ],
})
export class LoginComponent implements OnInit {
    // Form
    readonly loginForm: FormGroup
    private _hide = true

    // Store Selectors
    readonly error$: Observable<string | null>
    readonly isSubmitting$: Observable<boolean>

    // Route params
    private returnUrl = '/'

    constructor(
        private readonly _fb: FormBuilder,
        private readonly _store: Store,
        private readonly _route: ActivatedRoute
    ) {
        this.loginForm = this.buildForm()
        
        // Initialize store selectors
        this.error$ = this._store.select(selectAuthError)
        this.isSubmitting$ = this._store.select(selectIsSubmitting)
    }

    // Getter and setter for hide property
    get hide(): boolean {
        return this._hide
    }

    set hide(value: boolean) {
        this._hide = value
    }

    ngOnInit(): void {
        this.initializeRouteParams()
        this.initializeForm()
    }

    onSubmit(event: Event): void {
        event.preventDefault()

        if (this.loginForm.invalid) {
            return
        }

        const { email, password } = this.loginForm.value
        this._store.dispatch(
            AuthActions.login({
                email,
                password,
                returnUrl: this.returnUrl,
            })
        )
    }

    // Form Getters
    get email() {
        return this.loginForm.get('email')
    }

    get password() {
        return this.loginForm.get('password')
    }

    // Private Methods
    private buildForm(): FormGroup {
        return this._fb.group({
            email: ['', [Validators.required, Validators.email]],
            password: [
                '',
                [
                    Validators.required,
                    PasswordValidators.minMaxLength(8, 32),
                ],
            ],
        })
    }

    private initializeForm(): void {
        this.loginForm.reset({
            email: '',
            password: ''
        })
    }

    private initializeRouteParams(): void {
        this._route.queryParams
            .pipe(untilDestroyed(this))
            .subscribe(
                (params) => (this.returnUrl = params[RETURN_URL_QUERY_PARAM] || '/')
            )
    }
}
