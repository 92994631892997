import { Injectable } from '@angular/core'
import { ComponentStore } from '@ngrx/component-store'
import { tapResponse } from '@ngrx/operators'
import { Store } from '@ngrx/store'
import { Observable, pipe, switchMap, tap, exhaustMap, catchError, of } from 'rxjs'
import { AuthService } from '../../../shared/services/auth.service'
import { AuthActions } from '../actions/auth.actions'
import { HttpErrorResponse } from '@angular/common/http'
import { NotificationService } from 'src/app/shared/services/notification.service'
import { AppwriteException } from 'appwrite'
import { TranslocoService } from '@ngneat/transloco'

export interface RecoverState {
    isSubmitting: boolean
    backendError: string | null
    invalidTokenError: string | null
}

export const initialState: RecoverState = {
    isSubmitting: false,
    backendError: null,
    invalidTokenError: null,
}

export interface ChangePasswordRequest {
    userId: string
    secret: string
    password: string
    confirmPassword: string
}

@Injectable()
export class RecoverStore extends ComponentStore<RecoverState> {
    constructor(
        private authService: AuthService, 
        private store: Store,
        private _notification: NotificationService,
        private _transloco: TranslocoService
    ) {
        super(initialState)
    }
    readonly isSubmitting$: Observable<boolean> = this.select(
        (state) => state.isSubmitting
    )

    readonly backendError$: Observable<string | null> = this.select(
        (state) => state.backendError
    )
    readonly invalidTokenError$: Observable<string | null> = this.select(
        (state) => state.invalidTokenError
    )
    private readonly setIsSubmitting = this.updater(
        (state, isSubmitting: boolean) => ({
            isSubmitting: isSubmitting,
            backendError: state.backendError,
            invalidTokenError: state.invalidTokenError,
        })
    )

    private readonly setBackendError = this.updater(
        (state, error: string | null) => ({
            isSubmitting: false,
            invalidTokenError: null,
            backendError: error,
        })
    )
    private readonly setInvalidTokenError = this.updater(
        (state, error: string | null) => ({
            isSubmitting: false,
            invalidTokenError: error,
            backendError: null,
        })
    )

    readonly updatePassword = this.effect<ChangePasswordRequest>(
        pipe(
            tap(() => {
                this.setIsSubmitting(true)
                this._notification.loading()
            }),
            exhaustMap((request: ChangePasswordRequest) =>
                this.authService.resetPassword(request).pipe(
                    tap(() => {
                        this.setIsSubmitting(false)
                        this._notification.removeLoading()
                        this._notification.notifySuccess(this._transloco.translate('auth.password.updateSuccess'))
                    }),
                    catchError((error: HttpErrorResponse) => {
                        this._notification.removeLoading()
                        console.error('Password update error:', error);
                        
                        // Handle the specific error structure from backend
                        const errorResponse = error.error as AppwriteException;
                        const errorMessage = errorResponse?.message || 'An error occurred while updating password';
                        
                        this.setState({
                            isSubmitting: false,
                            backendError: errorMessage,
                            invalidTokenError: errorResponse?.type === 'user_invalid_token' ? errorMessage : null
                        });
                        
                        this._notification.notifyFailure(errorMessage);
                        return of(null);
                    })
                )
            )
        )
    )
}
