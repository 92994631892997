import { Component, OnInit } from '@angular/core'
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    ValidationErrors,
    Validators,
} from '@angular/forms'
import { ActivatedRoute, RouterModule } from '@angular/router'
import { provideComponentStore } from '@ngrx/component-store'
import { Observable } from 'rxjs'
import {
    ChangePasswordRequest,
    RecoverStore,
} from '../../store/states/recover.state'
import { CommonModule } from '@angular/common'
import { MatCardModule } from '@angular/material/card'
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { TranslocoModule } from '@ngneat/transloco'
import { MatButtonModule } from '@angular/material/button'
import { MatInputModule } from '@angular/material/input'

@Component({
    selector: 'app-recover',
    templateUrl: './recover.component.html',
    styleUrls: ['./recover.component.scss'],
    providers: [provideComponentStore(RecoverStore)],
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        MatCardModule,
        MatFormFieldModule,
        MatLabel,
        MatIconModule,
        MatButtonModule,
        TranslocoModule,
        MatInputModule
    ],
})
export class RecoverComponent implements OnInit {
    constructor(
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private componentStore: RecoverStore
    ) {
        this.recoverForm = fb.group(
            {
                password: [
                    '',
                    [
                        Validators.required,
                        Validators.minLength(8),
                        Validators.maxLength(40),
                    ],
                ],
                confirmPassword: ['', [Validators.required]],
            },
            { validators: this.passwordMatch }
        )
        this.backendError$ = this.componentStore.backendError$
        this.isSubmitting$ = this.componentStore.isSubmitting$
        this.invalidTokenError$ = this.componentStore.invalidTokenError$
        this.userId = ''
        this.secret = ''
    }

    userId: string
    secret: string
    error: string | null = null
    hide: boolean = true
    confirmHide: boolean = true
    backendError$: Observable<string | null>
    isSubmitting$: Observable<boolean>
    invalidTokenError$: Observable<string | null>
    recoverForm: FormGroup
    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            this.userId = params['userId']
            this.secret = params['secret']
        })
        if (this.userId == '' || this.secret == '') {
            this.error = 'invalid user or secret'
        }
        this.componentStore.setState({
            isSubmitting: false,
            backendError: null,
            invalidTokenError: null,
        })
    }
    onSubmit(e: any): void {
        e.preventDefault()
        // check if the password did not match then make the confirm password field shows error
        if (this.recoverForm.hasError('passwordMatch')) {
            this.recoverForm.controls['confirmPassword'].setErrors({
                passwordMatch: true,
            })
        }
        if (this.recoverForm.invalid) {
            return
        }
        console.log('valid:', this.recoverForm.value)
        const request: ChangePasswordRequest = {
            userId: this.userId,
            secret: this.secret,
            password: this.recoverForm.value.password,
            confirmPassword: this.recoverForm.value.confirmPassword,
        }
        this.componentStore.updatePassword(request)
    }

    passwordMatch(control: AbstractControl): ValidationErrors | null {
        return control.get('password')?.value ===
            control.get('confirmPassword')?.value
            ? null
            : { passwordMatch: true }
    }
    get password() {
        return this.recoverForm.get('password')
    }
    get confirmPassword() {
        return this.recoverForm.get('confirmPassword')
    }
}

