<mat-tab-group
  *transloco="let t; read: 'profile'"
  class="mat-tab-group"
  mat-stretch-tabs="false"
  mat-align-tabs="center"
>
  <mat-tab [label]="t('userInfo')">
    <!-- Name Form -->
    <form
      *transloco="let t; read: 'userInfo'"
      class="user-info-form"
      [formGroup]="nameForm"
      (ngSubmit)="onSubmitName($event)"
    >
      <mat-card class="user-info-card">
        <mat-card-header>
          <mat-card-title>{{ t('nameTitle') }}</mat-card-title>
        </mat-card-header>

        <mat-card-content>
          <!-- Avatar Section -->
          <div class="avatar-section">
            <div class="profile-img-container">
              <img
                class="profile-img"
                [src]="
                  (avatarInfo$ | async)?.avatarUrl ||
                  'assets/images/default-avatar.png'
                "
                [alt]="
                  'Profile picture of ' +
                  (nameForm.get('firstName')?.value || '')
                "
                (error)="handleImageProfileError($event)"
              />
              <div class="avatar-overlay" *ngIf="isSubmittingUpload$ | async">
                <mat-spinner diameter="30"></mat-spinner>
              </div>
              <button
                mat-mini-fab
                color="primary"
                class="edit-button"
                [disabled]="isSubmittingUpload$ | async"
                (click)="imageInput.click()"
              >
                <mat-icon>edit</mat-icon>
              </button>
            </div>

            <div class="avatar-actions">
              <button
                *ngIf="showUploadButton$ | async"
                mat-raised-button
                color="primary"
                type="button"
                [disabled]="isSubmittingUpload$ | async"
                (click)="uploadImage()"
              >
                {{ t('upload') }}
              </button>

              <button
                *ngIf="
                  (avatarInfo$ | async)?.hasAvatar &&
                  !(showUploadButton$ | async)
                "
                mat-raised-button
                color="warn"
                type="button"
                [disabled]="isSubmittingDelete$ | async"
                (click)="deleteAvatar()"
              >
                {{ t('deleteAvatar') }}
              </button>
            </div>

            <input
              #imageInput
              hidden
              type="file"
              accept=".jpg,.jpeg,.png,image/jpeg,image/jpg,image/png"
              (change)="selectFile($event)"
            />
          </div>

          <!-- Name Fields -->
          <div class="form-fields">
            <mat-form-field appearance="outline">
              <mat-label>{{ t('firstNameLabel') }}</mat-label>
              <input matInput formControlName="firstName" />
              <mat-error
                *ngIf="nameForm.get('firstName')?.errors?.['required']"
              >
                {{ t('firstNameLabel') }} {{ t('errorIsRequired') }}
              </mat-error>
              <mat-error *ngIf="nameForm.get('firstName')?.errors?.['pattern']">
                {{ t('firstNameLabel') }} {{ t('errorIsInvalid') }}
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>{{ t('lastNameLabel') }}</mat-label>
              <input matInput formControlName="lastName" />
              <mat-error *ngIf="nameForm.get('lastName')?.errors?.['required']">
                {{ t('lastNameLabel') }} {{ t('errorIsRequired') }}
              </mat-error>
              <mat-error *ngIf="nameForm.get('lastName')?.errors?.['pattern']">
                {{ t('lastNameLabel') }} {{ t('errorIsInvalid') }}
              </mat-error>
            </mat-form-field>
          </div>
        </mat-card-content>

        <mat-card-actions>
          <div class="button-container">
            <button
              mat-raised-button
              color="primary"
              type="submit"
              [disabled]="
                !nameForm.valid ||
                !nameForm.dirty ||
                (isSubmittingSave$ | async)
              "
            >
              {{ t('save') }}
            </button>
          </div>
        </mat-card-actions>
      </mat-card>
    </form>

    <!-- Phone Form -->
    <form
      *transloco="let t; read: 'userInfo'"
      class="phone-form"
      [formGroup]="phoneForm"
      (ngSubmit)="onSubmitPhone($event)"
    >
      <mat-card class="phone-card">
        <mat-card-header>
          <mat-card-title>{{ t('phoneTitle') }}</mat-card-title>
        </mat-card-header>

        <mat-card-content>
          <div class="form-fields">
            <mat-form-field appearance="outline">
              <mat-label>{{ t('phoneLabel') }}</mat-label>
              <input
                matInput
                formControlName="phone"
                placeholder="+55119988776655"
              />
              <mat-error *ngIf="phoneForm.get('phone')?.errors?.['pattern']">
                {{ t('phoneLabel') }} {{ t('errorIsInvalid') }}
              </mat-error>
            </mat-form-field>

            <mat-form-field
              *ngIf="showPasswordField$ | async"
              appearance="outline"
            >
              <mat-label>{{ t('passwordLabel') }}</mat-label>
              <input
                matInput
                [type]="showPassword ? 'text' : 'password'"
                formControlName="password"
              />
              <button
                mat-icon-button
                matSuffix
                type="button"
                (click)="showPassword = !showPassword"
              >
                <mat-icon>{{
                  showPassword ? 'visibility_off' : 'visibility'
                }}</mat-icon>
              </button>
              <mat-error
                *ngIf="phoneForm.get('password')?.errors?.['required']"
              >
                {{ t('passwordLabel') }} {{ t('errorIsRequired') }}
              </mat-error>
              <mat-hint>{{ t('passwordRequiredForPhone') }}</mat-hint>
            </mat-form-field>
          </div>
        </mat-card-content>

        <mat-card-actions>
          <div class="button-container">
            <button
              mat-raised-button
              color="primary"
              type="submit"
              [disabled]="
                !phoneForm.valid ||
                !phoneForm.dirty ||
                (isSubmittingSave$ | async)
              "
            >
              {{ t('save') }}
            </button>
          </div>
        </mat-card-actions>
      </mat-card>
    </form>
  </mat-tab>

  <!-- Address Tab -->
  <mat-tab [label]="t('address')">
    <form
      [formGroup]="addressForm"
      (ngSubmit)="onSubmitAddress($event)"
      class="address-form"
      *transloco="let t; read: 'userAddress'"
    >
      <mat-card class="address-card">
        <mat-card-header>
          <mat-card-title>{{ t('title') }}</mat-card-title>
        </mat-card-header>

        <mat-card-content>
          <div class="form-fields">
            <div class="form-row">
              <mat-form-field appearance="outline">
                <mat-label>{{ t('streetLabel') }}</mat-label>
                <input matInput formControlName="street" />
                <mat-error
                  *ngIf="addressForm.get('street')?.errors?.['required']"
                >
                  {{ t('streetLabel') }} {{ t('errorIsRequired') }}
                </mat-error>
                <mat-error
                  *ngIf="addressForm.get('street')?.errors?.['pattern']"
                >
                  {{ t('streetLabel') }} {{ t('errorIsInvalid') }}
                </mat-error>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>{{ t('houseNumberLabel') }}</mat-label>
                <input matInput formControlName="houseNo" />
                <mat-error
                  *ngIf="addressForm.get('houseNo')?.errors?.['required']"
                >
                  {{ t('houseNumberLabel') }} {{ t('errorIsRequired') }}
                </mat-error>
                <mat-error
                  *ngIf="addressForm.get('houseNo')?.errors?.['pattern']"
                >
                  {{ t('houseNumberLabel') }} {{ t('errorIsInvalid') }}
                </mat-error>
              </mat-form-field>
            </div>

            <div class="form-row">
              <mat-form-field appearance="outline">
                <mat-label>{{ t('countryLabel') }}</mat-label>
                <input
                  matInput
                  formControlName="country"
                  [matAutocomplete]="auto"
                  
                />
                <mat-autocomplete 
                #auto="matAutocomplete"
                [displayWith]="displayFn"
                (optionSelected)="onCountrySelected($event)">
                  <mat-option
                    *ngFor="let country of filteredCountries$ | async"
                    [value]="country"
                  >
                    <img
                      class="country-flag"
                      [src]="country.flag"
                      [alt]="country.name"
                    />
                    <span>{{ country.name }}</span>
                  </mat-option>
                </mat-autocomplete>
                <mat-error
                  *ngIf="addressForm.controls['country'].errors?.['countryValidator']?.error"
                >
                  {{ addressForm.controls['country'].errors?.['countryValidator']?.error | transloco }}
                </mat-error>
                <mat-error
                  *ngIf="addressForm.controls['country'].hasError('required') && !addressForm.controls['country'].errors?.['countryValidator']"
                >
                  {{ 'validations.country.countryRequired' | transloco }}
                </mat-error>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>{{ t('stateLabel') }}</mat-label>
                <input matInput formControlName="state" />
                <mat-error
                  *ngIf="addressForm.get('state')?.errors?.['required']"
                >
                  {{ t('stateLabel') }} {{ t('errorIsRequired') }}
                </mat-error>
                <mat-error
                  *ngIf="addressForm.get('state')?.errors?.['pattern']"
                >
                  {{ t('stateLabel') }} {{ t('errorIsInvalid') }}
                </mat-error>
              </mat-form-field>
            </div>

            <div class="form-row">
              <mat-form-field appearance="outline">
                <mat-label>{{ t('cityLabel') }}</mat-label>
                <input matInput formControlName="city" />
                <mat-error
                  *ngIf="addressForm.get('city')?.errors?.['required']"
                >
                  {{ t('cityLabel') }} {{ t('errorIsRequired') }}
                </mat-error>
                <mat-error *ngIf="addressForm.get('city')?.errors?.['pattern']">
                  {{ t('cityLabel') }} {{ t('errorIsInvalid') }}
                </mat-error>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>{{ t('postalCodeLabel') }}</mat-label>
                <input matInput formControlName="postCode" />
                <mat-error
                  *ngIf="addressForm.get('postCode')?.errors?.['required']"
                >
                  {{ t('postalCodeLabel') }} {{ t('errorIsRequired') }}
                </mat-error>
                <mat-error
                  *ngIf="addressForm.get('postCode')?.errors?.['maxlength']"
                >
                  {{ t('postalCodeLabel') }} {{ t('errorIsInvalid') }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </mat-card-content>

        <mat-card-actions>
          <div class="button-container">
            <button
              mat-raised-button
              color="primary"
              type="submit"
              [disabled]="
                !addressForm.valid ||
                !addressForm.dirty ||
                (isSubmittingAddressSave$ | async)
              "
            >
              {{ t('save') }}
            </button>
          </div>
        </mat-card-actions>
      </mat-card>
    </form>
  </mat-tab>

  <!-- Security Tab (Placeholder) -->
  <mat-tab [label]="t('security')">
    <form
      *transloco="let t; read: 'security'"
      class="password-form"
      [formGroup]="passwordForm"
      (ngSubmit)="onSubmitPassword($event)"
    >
      <mat-card class="password-card">
        <mat-card-header>
          <mat-card-title>{{ t('title') }}</mat-card-title>
        </mat-card-header>

        <mat-card-content>
          <div class="form-fields">
            <mat-form-field appearance="outline">
              <mat-label>{{ t('currentPasswordLabel') }}</mat-label>
              <input
                matInput
                type="password"
                formControlName="currentPassword"
              />
              <mat-error
                *ngIf="
                  passwordForm.get('currentPassword')?.hasError('required')
                "
              >
                {{ t('currentPasswordLabel') }} {{ t('errorIsRequired') }}
              </mat-error>
              <mat-error
                *ngIf="
                  passwordForm.get('currentPassword')?.hasError('minlength')
                "
              >
                {{ t('errorMinValue') }}
              </mat-error>
              <mat-error
                *ngIf="
                  passwordForm.get('currentPassword')?.hasError('maxlength')
                "
              >
                {{ t('errorMaxValue') }}
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>{{ t('newPasswordLabel') }}</mat-label>
              <input matInput type="password" formControlName="newPassword" />
              <mat-error
                *ngIf="passwordForm.get('newPassword')?.hasError('required')"
              >
                {{ t('newPasswordLabel') }} {{ t('errorIsRequired') }}
              </mat-error>
              <mat-error
                *ngIf="passwordForm.get('newPassword')?.hasError('minlength')"
              >
                {{ t('errorMinValue') }}
              </mat-error>
              <mat-error
                *ngIf="passwordForm.get('newPassword')?.hasError('maxlength')"
              >
                {{ t('errorMaxValue') }}
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>{{ t('confirmPasswordLabel') }}</mat-label>
              <input
                matInput
                type="password"
                formControlName="confirmPassword"
              />
              <mat-error
                *ngIf="
                  passwordForm.get('confirmPassword')?.hasError('required')
                "
              >
                {{ t('confirmPasswordLabel') }} {{ t('errorIsRequired') }}
              </mat-error>
              <mat-error
                *ngIf="
                  passwordForm.get('confirmPassword')?.hasError('mismatch')
                "
              >
                {{ t('errorNotMatch') }}
              </mat-error>
            </mat-form-field>
          </div>
        </mat-card-content>

        <mat-card-actions>
          <div class="button-container">
            <button
              mat-raised-button
              color="primary"
              type="submit"
              [disabled]="
                passwordForm.invalid || (isSubmittingPasswordSave$ | async)
              "
            >
              <mat-spinner
                diameter="20"
                *ngIf="isSubmittingPasswordSave$ | async"
              ></mat-spinner>
              <span *ngIf="!(isSubmittingPasswordSave$ | async)">{{
                t('save')
              }}</span>
            </button>
          </div>
        </mat-card-actions>
      </mat-card>
    </form>
  </mat-tab>
</mat-tab-group>
