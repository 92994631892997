<div class="recover-page flex-center">
  <form
    *transloco="let t; read: 'recover'"
    class="recover-form"
    [formGroup]="recoverForm"
    (ngSubmit)="onSubmit($event)"
  >
    <mat-card class="recover-card">
      <mat-card-header class="flex-center">
        <mat-card-title>
          <h1 class="text-2xl sm:text-3xl">{{ t('title') }}</h1>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="row">
          <div class="col">
            <mat-form-field appearance="fill" class="full-width">
              <mat-label>{{ t('passwordLabel') }}</mat-label>
              <input
                matInput
                [type]="hide ? 'password' : 'text'"
                placeholder="{{ t('passwordPlaceHolder') }}"
                formControlName="password"
                required
              />
              <button
                mat-icon-button
                matSuffix
                (click)="hide = !hide"
                [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide"
              >
                <mat-icon>{{
                  hide ? 'visibility_off' : 'visibility'
                }}</mat-icon>
              </button>
              <mat-error *ngIf="password?.hasError('required')">{{
                t('errorPasswordIsRequired')
              }}</mat-error>
              <mat-error *ngIf="password?.hasError('minlength')">{{
                t('errorMinValue')
              }}</mat-error>
              <mat-error *ngIf="password?.hasError('maxlength')">{{
                t('errorMaxValue')
              }}</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <mat-form-field appearance="fill" class="full-width">
              <mat-label>{{ t('confirmPasswordLabel') }}</mat-label>
              <input
                matInput
                [type]="confirmHide ? 'password' : 'text'"
                placeholder="{{ t('confirmPasswordPlaceholder') }}"
                formControlName="confirmPassword"
                required
              />
              <button
                mat-icon-button
                matSuffix
                (click)="confirmHide = !confirmHide"
                [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="confirmHide"
              >
                <mat-icon>{{
                  confirmHide ? 'visibility_off' : 'visibility'
                }}</mat-icon>
              </button>
              <mat-error *ngIf="confirmPassword?.hasError('required')">{{
                t('errorPasswordIsRequired')
              }}</mat-error>
              <mat-error *ngIf="confirmPassword?.hasError('passwordMatch')">{{
                t('errorNotMatch')
              }}</mat-error>
            </mat-form-field>
            <div *ngIf="backendError$ | async" class="error-message">
              <mat-icon class="error-icon">error_outline</mat-icon>
              {{ backendError$ | async }}
            </div>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions class="flex-center full-width">
        <div class="row flex-center">
          <div class="row">
            <p class="text-sm text-gray-500">
              {{ t('backLink') }}
              <a [routerLink]="['/auth/login']" class="underline">{{
                t('loginLink')
              }}</a>
            </p>
          </div>
          <div class="col">
            <button
              type="submit"
              [disabled]="isSubmitting$ | async"
              mat-raised-button
              color="accent"
            >
              {{ t('submitLabel') }}
            </button>
          </div>
        </div>
      </mat-card-actions>
    </mat-card>
  </form>

  <div *ngIf="invalidTokenError$ | async" class="error-message">
    {{ invalidTokenError$ | async }}
  </div>
</div>
