import { createActionGroup, emptyProps, props } from '@ngrx/store'
import {
    AuthState,
    User,
    UserAddress,
} from '../../interfaces'

export const AuthActions = createActionGroup({
    source: 'Auth',
    events: {
        'Load User Address': props<{ userAddress: UserAddress | null }>(),

        // auth check
        'Check Auth': emptyProps(),
        'Auth Success': props<{ authState: AuthState }>(),
        'Auth Failure': emptyProps(),

        // Login Actions
        Login: props<{ email: string; password: string; returnUrl: string }>(),
        'Login Failure': props<{ error: string }>(),
        'Login Success': props<{
            auth: AuthState
            returnUrl: string
            isLoggedIn: boolean
        }>(),

        // Logout
        Logout: emptyProps(),

        // Register
        'Register Success': props<{ currentUser: User }>(),

        // Password Recovery
        'Recover Success': emptyProps(),

        // Refresh Actions
        'Refresh Auth State': props<{ auth: AuthState }>(),
        'Refresh User': props<{ user: User }>(),
        'Refresh Current User': props<{ user: User }>(),
    },
})
