import { Injectable } from '@angular/core'
import { ComponentStore } from '@ngrx/component-store'
import { Store } from '@ngrx/store'
import { Observable, exhaustMap, pipe, tap, catchError, of } from 'rxjs'
import { NotificationService } from 'src/app/shared/services/notification.service'
import { AuthService } from '../../../shared/services/auth.service'
import { AuthActions } from '../actions/auth.actions'
import { AppwriteException } from 'appwrite'

export interface RegisterState {
    isSubmitting: boolean
    backendError: string | null
}

export const RETURN_URL_QUERY_PARAM = 'return_url'

export const initialState: RegisterState = {
    isSubmitting: false,
    backendError: null,
}

export interface RegisterRequestInterface {
    email: string
    password: string
    name: string
    isMasjidAdmin: boolean
}

@Injectable()
export class RegisterStore extends ComponentStore<RegisterState> {
    constructor(
        private authService: AuthService,
        private store: Store,
        private _notification: NotificationService
    ) {
        super(initialState)
    }
    readonly isSubmitting$: Observable<boolean> = this.select(
        (state) => state.isSubmitting
    )

    readonly backendError$: Observable<string | null> = this.select(
        (state) => state.backendError
    )

    private readonly setIsSubmitting = this.updater(
        (state, isSubmitting: boolean) => ({
            isSubmitting: isSubmitting,
            backendError: state.backendError,
        })
    )

    private readonly setBackendError = this.updater(
        (state, error: string | null) => ({
            isSubmitting: false,
            backendError: error,
        })
    )

    readonly doRegister = this.effect<RegisterRequestInterface>(
        pipe(
            tap(() => {
                this.setIsSubmitting(true)
                this._notification.loading()
            }),
            exhaustMap((request: RegisterRequestInterface) =>
                this.authService.register(request).pipe(
                    tap((currentUser) => {
                        this.store.dispatch(
                            AuthActions.registerSuccess({ currentUser })
                        )
                        this.setIsSubmitting(false)
                        this._notification.removeLoading()
                        this._notification.notifySuccess(
                            'Registration successful! Welcome to the app.'
                        )
                    }),
                    catchError((err: Error | AppwriteException) => {
                        this._notification.removeLoading()
                        console.log('register error:', err)
                        if (err instanceof AppwriteException && err.code === 409) {
                            this.setBackendError('User already exists')
                            this._notification.notifyFailure(
                                'User already exists'
                            )
                            return of(null)
                        }
                        this.setBackendError(err.message)
                        this._notification.notifyFailure(err.message)

                        return of(null)
                    })
                )
            )
        )
    )
}
