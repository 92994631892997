import { Component, OnInit } from '@angular/core'
import {
    FormBuilder,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms'
import { Observable } from 'rxjs'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { MatCardModule } from '@angular/material/card'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatButtonModule } from '@angular/material/button'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { TranslocoModule } from '@ngneat/transloco'
import { MatInputModule } from '@angular/material/input'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { Store } from '@ngrx/store'
import { AuthService } from 'src/app/shared/services/auth.service'
import { PasswordValidators } from 'src/app/shared/validators/password.validator'
import { LengthValidators } from 'src/app/shared/validators/length.validator'
import { PatternValidators } from 'src/app/shared/validators/pattern.validator'
import { RegisterStore } from '../../store/states/register.state'

@Component({
    selector: 'mi-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
    standalone: true,
    providers: [RegisterStore],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        MatCardModule,
        MatFormFieldModule,
        MatIconModule,
        MatButtonModule,
        MatSlideToggleModule,
        TranslocoModule,
        MatInputModule,
        MatProgressSpinnerModule
    ],
})
export class RegisterComponent implements OnInit {
    signupForm: FormGroup
    hide = true
    confirmHide = true
    backendError$: Observable<string | null>
    isSubmitting$: Observable<boolean>

    constructor(
        private _fb: FormBuilder,
        private _registerStore: RegisterStore
    ) {
        this.signupForm = this._fb.group({
            firstName: ['', [
                Validators.required,
                Validators.pattern(PatternValidators.LETTERS_AND_SPACES),
                LengthValidators.minMaxLength(2, 50)
            ]],
            lastName: ['', [
                Validators.required,
                Validators.pattern(PatternValidators.LETTERS_AND_SPACES),
                LengthValidators.minMaxLength(2, 50)
            ]],
            email: ['', [
                Validators.required, 
                Validators.email
            ]],
            password: ['', [
                Validators.required,
                PasswordValidators.minMaxLength(8, 32),
                PasswordValidators.strength()
            ]],
            confirmPassword: ['', [
                Validators.required
            ]],
            isMasjidAdmin: [false]
        }, {
            validators: PasswordValidators.match('password', 'confirmPassword')
        })

        // Get error and loading states from the store
        this.backendError$ = this._registerStore.backendError$
        this.isSubmitting$ = this._registerStore.isSubmitting$
    }

    ngOnInit(): void {}

    onSubmit(event: Event): void {
        event.preventDefault()
        if (this.signupForm.valid) {
            const { firstName, lastName, email, password, isMasjidAdmin } = this.signupForm.value
            this._registerStore.doRegister({
                name: `${firstName.trim()}|${lastName.trim()}`,
                email,
                password,
                isMasjidAdmin
            })
        }
    }
}
