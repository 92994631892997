import { Component, OnInit, OnDestroy } from '@angular/core'
import {
    FormBuilder,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms'
import { Store } from '@ngrx/store'
import { Observable, filter, map, startWith, take, withLatestFrom, switchMap } from 'rxjs'
import { Country } from 'src/app/shared/interfaces/country.interface'
import { AuthService } from '../../../shared/services/auth.service'
import { selectAuthState } from '../../store/selectors/auth.selectors'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { MatTabsModule } from '@angular/material/tabs'
import { MatCardModule } from '@angular/material/card'
import { MatIconModule } from '@angular/material/icon'
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field'
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatInputModule } from '@angular/material/input'
import { MatButtonModule } from '@angular/material/button'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { TranslocoModule } from '@ngneat/transloco'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import Utils from 'src/app/shared/services/utils'
import { ProfileStore } from '../../store/states/profile.state'
import { FileStorageService } from '../../../shared/services/file-storage.service'
import { UserAddressDocumentService } from '../../../shared/services/user-address.service'
import { NotificationService } from '../../../shared/services/notification.service'
import { ProfileActions } from '../../store/actions/profile.actions'
import { CountryService } from 'src/app/shared/services/country.service'
import { CountryValidators } from 'src/app/shared/validators/country.validator'
import { User } from '../../interfaces/user.interface'

@UntilDestroy()
@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        MatTabsModule,
        MatCardModule,
        MatIconModule,
        MatFormFieldModule,
        MatLabel,
        MatAutocompleteModule,
        MatExpansionModule,
        TranslocoModule,
        MatInputModule,
        MatButtonModule,
        MatProgressSpinnerModule,
    ],
    providers: [ProfileStore]
})
export class ProfileComponent implements OnInit, OnDestroy {
    // Form definitions
    nameForm: FormGroup;
    phoneForm: FormGroup;
    addressForm: FormGroup;
    passwordForm: FormGroup;

    // Observables from store
    authUser$ = this.store.select(selectAuthState);
    avatarInfo$ = this.profileStore.avatarInfo$;
    isSubmittingUpload$ = this.profileStore.isSubmittingUpload$;
    isSubmittingDelete$ = this.profileStore.isSubmittingDelete$;
    isSubmittingSave$ = this.profileStore.isSubmittingSave$;
    isSubmittingAddressSave$ = this.profileStore.isSubmittingAddressSave$;
    isSubmittingPasswordSave$ = this.profileStore.isSubmittingPasswordSave$;
    showUploadButton$ = this.profileStore.showUploadButton$;
    errorMessage$ = this.profileStore.errorMessage$;
    showPasswordField$: Observable<boolean>;

    // Properties
    countries: Country[] = [];
    filteredCountries$: Observable<Country[]>;
    selectedCountry: Country | null = null;
    displayFn = CountryValidators.displayFn;
    showPassword = false;
    avatarFile: File | null = null;

    // Initial form values
    private initialNameValues: {[key: string]: any} = {};
    private initialPhoneValues: {[key: string]: any} = {};
    private initialAddressValues: {[key: string]: any} = {};

    constructor(
        private fb: FormBuilder,
        private store: Store,
        private authService: AuthService,
        private profileStore: ProfileStore,
        private fileService: FileStorageService,
        private userAddressService: UserAddressDocumentService,
        private notificationService: NotificationService,
        private _countryService: CountryService
    ) {
        this.initializeForms();
        this.setupFormValidation();
    }

    private initializeForms(): void {
        this.nameForm = this.fb.group({
            firstName: ['', [
                Validators.required,
                Validators.maxLength(50),
                Validators.pattern(/^[a-zA-Z\s]*$/),
            ]],
            lastName: ['', [
                Validators.required,
                Validators.maxLength(50),
                Validators.pattern(/^[a-zA-Z\s]*$/),
            ]]
        });

        this.phoneForm = this.fb.group({
            phone: ['', [
                Validators.maxLength(30), 
                Validators.pattern(/^\+?[0-9\s-()]*$/)
            ]],
            password: [{ value: '', disabled: true }]
        });

        this.addressForm = this.fb.group({
            street: ['', [
                Validators.required,
                Validators.pattern('^([0-9]+[ ]?)?([a-zA-Z]+[ ]?){0,}?'),
            ]],
            houseNo: ['', [
                Validators.required,
                Validators.pattern('^([0-9]+)[ ]?([a-zA-Z]{1,3})?'),
            ]],
            country: ['', [Validators.required]],
            city: ['', [
                Validators.required,
                Validators.pattern("^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$"),
            ]],
            state: ['', [
                Validators.required,
                Validators.pattern("^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$"),
            ]],
            postCode: ['', [Validators.required, Validators.maxLength(10)]]
        });

        this.passwordForm = this.fb.group({
            currentPassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(40)]],
            newPassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(40)]],
            confirmPassword: ['', [Validators.required]]
        }, { validators: ProfileComponent.passwordsMatchValidator });
    }

    private setupFormValidation(): void {
        this.filteredCountries$ = this.addressForm.get('country')!.valueChanges.pipe(
            startWith(''),
            map(value => CountryValidators.filterCountriesSync(value || '', this.countries))
        );

        this.showPasswordField$ = this.phoneForm.get('phone')!.valueChanges.pipe(
            startWith(this.phoneForm.get('phone')!.value),
            withLatestFrom(this.authUser$),
            map(([phoneValue, auth]) => {
                const hasValue = !!phoneValue && phoneValue.trim() !== '';
                const hasChanged = phoneValue !== auth?.currentUser?.phone;
                return hasValue && hasChanged;
            })
        );
    }

    ngOnInit(): void {
        this.loadUserData();
        this.loadUserAddress();
        this.setupPasswordFieldControl();
        this.loadCountries();
        this.initializeAvatarInfo();
    }

    ngOnDestroy(): void {
        // Cleanup is handled by @UntilDestroy() decorator
    }

    // Form submission handlers
    onSubmitName(event: Event): void {
        event.preventDefault();
        
        if (this.nameForm.valid && this.nameForm.dirty) {
            const formValue = this.nameForm.value;
            this.store.dispatch(ProfileActions.updateName({
                firstName: formValue.firstName,
                lastName: formValue.lastName
            }));
        }
    }

    onSubmitPhone(event: Event): void {
        event.preventDefault();
        
        if (this.phoneForm.valid && this.phoneForm.dirty) {
            const formValue = this.phoneForm.value;
            this.store.dispatch(ProfileActions.updatePhone({
                phone: formValue.phone || '',
                password: formValue.password || ''
            }));
        }
    }

    onSubmitAddress(event: Event): void {
        event.preventDefault();
        
        if (this.addressForm.valid && this.addressForm.dirty) {
            this.authUser$.pipe(
                take(1),
                filter(auth => !!auth?.currentUser?.$id)
            ).subscribe(auth => {
                const formValue = this.addressForm.value;
                this.store.dispatch(ProfileActions.updateAddress({
                    userID: auth!.currentUser!.$id,
                    address: {
                        street: formValue.street,
                        houseNo: formValue.houseNo,
                        city: formValue.city,
                        state: formValue.state,
                        country: formValue.country,
                        postCode: formValue.postCode
                    }
                }));
            });
        }
    }

    onSubmitPassword(event: Event): void {
        event.preventDefault();
        
        if (this.passwordForm.valid) {
            const formValue = this.passwordForm.value;
            this.store.dispatch(ProfileActions.updatePassword({
                currentPassword: formValue.currentPassword,
                newPassword: formValue.newPassword
            }));
        }
    }

    // Avatar handling
    selectFile(event: Event): void {
        const element = event.target as HTMLInputElement;
        if (element.files && element.files.length > 0) {
            const file = element.files[0];
            
            // Validate file type and size
            if (!file.type.startsWith('image/')) {
                this.profileStore.setError('Only image files are allowed');
                return;
            }
            
            const maxSize = 5 * 1024 * 1024; // 5MB
            if (file.size > maxSize) {
                this.profileStore.setError('File size should not exceed 5MB');
                return;
            }
            
            this.avatarFile = file;
            
            const fileReader = new FileReader();
            fileReader.onload = (e) => {
                const previewUrl = e.target?.result as string;
                this.profileStore.setAvatarInfo({
                    avatarUrl: previewUrl,
                    hasAvatar: true
                });
                this.profileStore.setShowUploadButton(true);
                this.profileStore.setError(null);
            };
            fileReader.readAsDataURL(this.avatarFile);
        }
    }

    async uploadImage(): Promise<void> {
        if (!this.avatarFile) return;

        try {
            // Convert file to base64
            const base64Data = await new Promise<string>((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result as string);
                reader.onerror = reject;
                reader.readAsDataURL(this.avatarFile!);
            });

            this.authUser$.pipe(
                take(1),
                filter(auth => !!auth?.currentUser)
            ).subscribe(auth => {
                this.store.dispatch(ProfileActions.uploadAvatar({
                    user: auth!.currentUser!,
                    userID: auth!.currentUser!.$id,
                    base64Data: base64Data,
                    fileName: this.avatarFile!.name,
                    fileType: this.avatarFile!.type
                }));
                this.avatarFile = null;
                this.profileStore.setShowUploadButton(false);
            });
        } catch (error) {
            console.error('Error preparing file for upload:', Utils.handleErrorMessage(error));
            this.notificationService.notifyFailure(
                'Failed to process file for upload'
            );
        }
    }

    deleteAvatar(): void {
        this.authUser$.pipe(
            take(1),
            filter(auth => !!auth?.currentUser?.avatarId)
        ).subscribe(auth => {
            this.store.dispatch(ProfileActions.deleteAvatar({
                user: auth!.currentUser!
            }));
            this.profileStore.setAvatarInfo({
                avatarUrl: 'assets/images/profile-avatar.svg',
                hasAvatar: false
            });
        });
    }

    handleImageProfileError(event: Event): void {
        const target = event.target as HTMLImageElement;
        target.src = 'assets/images/profile-avatar.svg';
        this.profileStore.setAvatarInfo({
            avatarUrl: 'assets/images/profile-avatar.svg',
            hasAvatar: false
        });
    }

    // Private helper methods
    private loadUserData(): void {
        this.authUser$.pipe(
            take(1),
            filter(auth => !!auth?.currentUser),
            untilDestroyed(this)
        ).subscribe(auth => {
            const nameData = {
                firstName: auth?.currentUser?.firstName || '',
                lastName: auth?.currentUser?.lastName || ''
            };
            
            this.initialNameValues = { ...nameData };
            this.nameForm.patchValue(nameData);
            this.nameForm.markAsPristine();

            if (auth?.currentUser?.phone) {
                const phoneData = {
                    phone: auth.currentUser.phone,
                    password: ''
                };
                this.initialPhoneValues = { ...phoneData };
                this.phoneForm.patchValue(phoneData);
                this.phoneForm.markAsPristine();
            }

            // Subscribe to form changes
            this.nameForm.valueChanges.pipe(
                untilDestroyed(this)
            ).subscribe(() => {
                if (!Utils.checkForFormChanges(this.initialNameValues, this.nameForm)) {
                    this.nameForm.markAsPristine();
                }
            });

            this.phoneForm.valueChanges.pipe(
                untilDestroyed(this)
            ).subscribe(() => {
                if (!Utils.checkForFormChanges(this.initialPhoneValues, this.phoneForm)) {
                    this.phoneForm.markAsPristine();
                }
            });
        });
    }

    private setupPasswordFieldControl(): void {
        this.showPasswordField$.pipe(
            untilDestroyed(this)
        ).subscribe(shouldEnable => {
            const passwordControl = this.phoneForm.get('password');
            if (shouldEnable) {
                passwordControl?.enable();
                passwordControl?.setValidators([Validators.required]);
            } else {
                passwordControl?.disable();
                passwordControl?.clearValidators();
            }
            passwordControl?.updateValueAndValidity();
        });
    }

    private loadUserAddress(): void {
        this.authUser$.pipe(
            take(1),
            filter(auth => !!auth?.currentUser?.$id),
            switchMap(auth => this.userAddressService.findUserAddressDocument(auth.currentUser!.$id)),
            untilDestroyed(this)
        ).subscribe(address => {
            if (address) {
                const addressData = {
                    street: address.street || '',
                    houseNo: address.houseNo || '',
                    country: address.country || '',
                    city: address.city || '',
                    state: address.state || '',
                    postCode: address.postCode || ''
                };
                
                this.initialAddressValues = { ...addressData };
                this.addressForm.patchValue(addressData);
                this.addressForm.markAsPristine();
            }
        });

        this.addressForm.valueChanges.pipe(
            untilDestroyed(this)
        ).subscribe(() => {
            if (!Utils.checkForFormChanges(this.initialAddressValues, this.addressForm)) {
                this.addressForm.markAsPristine();
            }
        });
    }
    onCountrySelected(event: MatAutocompleteSelectedEvent): void {
        CountryValidators.handleCountrySelection(
            event,
            this.addressForm.get('country')!,
            this
        );
    }
    private loadCountries(): void {
        this._countryService.getCountries().pipe(
            untilDestroyed(this)
        ).subscribe(countries => {
            this.countries = countries;
            this.addressForm.get('country')?.setValidators([
                Validators.required,
                CountryValidators.validCountry(this.countries)
            ]);
            this.addressForm.get('country')?.updateValueAndValidity();
        });
    }

    private initializeAvatarInfo(): void {
        this.authUser$.pipe(
            take(1),
            filter(auth => !!auth?.currentUser?.$id),
            untilDestroyed(this)
        ).subscribe(auth => {
            if (auth?.currentUser?.avatarId) {
                const avatarUrl = this.fileService.getAvatarUrl(auth.currentUser.avatarId);
                this.profileStore.setAvatarInfo({ avatarUrl, hasAvatar: true });
            } else {
                this.profileStore.setAvatarInfo({ 
                    avatarUrl: 'assets/images/profile-avatar.svg', 
                    hasAvatar: false 
                });
            }
        });
    }

    static passwordsMatchValidator(formGroup: FormGroup) {
        const newPassword = formGroup.get('newPassword')?.value;
        const confirmPassword = formGroup.get('confirmPassword')?.value;
        
        if (newPassword !== confirmPassword) {
            formGroup.get('confirmPassword')?.setErrors({ mismatch: true });
            return { mismatch: true };
        } else {
            return null;
        }
    }
}
