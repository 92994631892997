<div class="login-page flex-center">
  <mat-card class="login-card">
    <form
      [formGroup]="loginForm"
      (ngSubmit)="onSubmit($event)"
      class="login-form"
    >
      <mat-card-header>
        <mat-card-title>
          <span><mat-icon>login</mat-icon></span>
          {{ 'login.title' | transloco }}
        </mat-card-title>
      </mat-card-header>

      <!-- Email Field -->
      <mat-form-field appearance="outline" class="form-field">
        <mat-label>{{ 'login.email' | transloco }}</mat-label>
        <input matInput type="email" formControlName="email" required />
        <mat-error *ngIf="email?.errors?.['required']">
          {{ 'login.emailRequired' | transloco }}
        </mat-error>
        <mat-error *ngIf="email?.errors?.['email']">
          {{ 'login.emailInvalid' | transloco }}
        </mat-error>
      </mat-form-field>

      <!-- Password Field -->
      <mat-form-field appearance="outline" class="form-field">
        <mat-label>{{ 'login.password' | transloco }}</mat-label>
        <input
          matInput
          [type]="hide ? 'password' : 'text'"
          formControlName="password"
          required
        />
        <button mat-icon-button matSuffix type="button" (click)="hide = !hide">
          <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>
        <mat-error *ngIf="password?.errors?.['required']">
          {{ 'login.passwordRequired' | transloco }}
        </mat-error>
        <mat-error *ngIf="password?.errors?.['minlength']">
          {{ 'login.passwordMinLength' | transloco }}
        </mat-error>
      </mat-form-field>

      <!-- Error Messages -->
      <div
        class="error-message"
        [class.show]="error$ | async"
        *ngIf="error$ | async"
      >
        <mat-icon class="error-icon">error_outline</mat-icon>
        <span>{{ 'login.invalidCredentials' | transloco }}</span>
      </div>

      <!-- Submit Button -->
      <button
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="loginForm.invalid || (isSubmitting$ | async)"
      >
        {{
          (isSubmitting$ | async)
            ? ('login.loggingIn' | transloco)
            : ('login.submit' | transloco)
        }}
      </button>

      <!-- Links -->
      <div class="form-links">
        <a routerLink="/auth/reset">{{ 'login.forgotPassword' | transloco }}</a>
        <a routerLink="/auth/register">{{ 'login.register' | transloco }}</a>
      </div>
    </form>
  </mat-card>
</div>
