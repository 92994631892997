import { Injectable } from '@angular/core'
import { ComponentStore } from '@ngrx/component-store'
import { tapResponse } from '@ngrx/operators'
import { Store } from '@ngrx/store'
import { Observable, pipe, switchMap, tap } from 'rxjs'
import { AuthService } from '../../../shared/services/auth.service'

export interface ResetState {
    isSubmitting: boolean
    backendError: string | null
    emailSent: boolean
}

export const initialState: ResetState = {
    isSubmitting: false,
    backendError: null,
    emailSent: false,
}

@Injectable()
export class ResetStore extends ComponentStore<ResetState> {
    constructor(private authService: AuthService, private store: Store) {
        super(initialState)
    }
    readonly isSubmitting$: Observable<boolean> = this.select(
        (state) => state.isSubmitting
    )

    readonly backendError$: Observable<string | null> = this.select(
        (state) => state.backendError
    )

    readonly emailSent$: Observable<boolean> = this.select(
        (state) => state.emailSent
    )
    private readonly setIsSubmitting = this.updater(
        (state, isSubmitting: boolean) => ({
            isSubmitting: isSubmitting,
            backendError: state.backendError,
            emailSent: state.emailSent,
        })
    )

    private readonly setEmailSent = this.updater(
        (state, emailSent: boolean) => ({
            isSubmitting: state.isSubmitting,
            backendError: null,
            emailSent: emailSent,
        })
    )
    private readonly setBackendError = this.updater(
        (state, error: string | null) => ({
            isSubmitting: false,
            emailSent: false,
            backendError: error,
        })
    )

    readonly sendResetEmail = this.effect<string>(
        pipe(
            tap(() => this.setIsSubmitting(true)),
            switchMap((email: string) =>
                this.authService.forgotPassword(email).pipe(
                    tapResponse(
                        (token) => {
                            console.log('token login state:', token)
                            this.setIsSubmitting(false)
                            this.setEmailSent(true)
                        },
                        (error: Error) => {
                            console.log('reset error:', error)
                            this.setBackendError(error.message)
                        }
                    )
                )
            )
        )
    )
}
