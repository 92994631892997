<div class="reset-page">
  <ng-container *transloco="let t; read: 'reset'">
    <form
      class="login-form"
      [formGroup]="resetForm"
      (ngSubmit)="onSubmit($event)"
      *ngIf="!(emailSentSuccessfully$ | async); else successful"
    >
      <mat-card class="reset-card">
        <mat-card-header class="flex-center">
          <mat-card-title>
            <h2 class="text-2xl sm:text-3xl">{{ t('title') }}</h2>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="reset-card-content">
          <p>
            {{ t('resetInstructions') }}
          </p>
        </mat-card-content>
        <div class="card-img flex-center">
          <img
            class="reset-img"
            mat-card-xl-image
            src="/assets/images/reset/email-reset-illustration.png"
            alt="reset-illustration"
          />
        </div>
        <mat-card-content>
          <div class="row">
            <div class="col">
              <mat-form-field appearance="fill" class="full-width">
                <mat-label>{{ t('emailLabel') }}</mat-label>
                <input
                  matInput
                  type="email"
                  placeholder="example@mail.com"
                  formControlName="email"
                  required
                />
                <mat-error *ngIf="email?.hasError('required')">{{
                  t('errorEmailRequired')
                }}</mat-error>
                <mat-error *ngIf="email?.hasError('email')">{{
                  t('errorEmailInvalid')
                }}</mat-error>
              </mat-form-field>
            </div>
          </div>

          <!-- Error Messages -->
          <div class="error-message" [class.show]="backendError$ | async" *ngIf="backendError$ | async">
            <mat-icon class="error-icon">error_outline</mat-icon>
            <span>{{ t('errorEmailNotRegistered') }}</span>
          </div>
        </mat-card-content>
        <mat-card-actions class="full-width reset-card-actions">
          <div class="row">
            <p>
              <a [routerLink]="['/auth/login']" class="underline">
                {{ t('loginLink') }}
              </a>
            </p>
          </div>
          <div class="row">
            <button
              type="submit"
              [disabled]="isSubmitting$ | async"
              mat-raised-button
              color="accent"
            >
              {{ t('submitRecover') }}
            </button>
          </div>
        </mat-card-actions>
      </mat-card>
    </form>
  </ng-container>
</div>

<ng-template #successful>
  <ng-container *transloco="let t; read: 'reset'">
    <mat-card class="reset-card">
      <mat-card-header class="flex-center">
        <mat-card-title>
          <h2>{{ t('successTitle') }}</h2>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content class="reset-card-content">
        <p>
          {{ t('resetSuccessInstructions') }}
        </p>
      </mat-card-content>
      <div class="card-img flex-center">
        <img
          class="reset-img"
          mat-card-xl-image
          src="/assets/images/reset/reset-success.png"
          alt="reset-illustration"
        />
      </div>
      <mat-card-actions class="full-width flex-center vertical-padding">
        <div class="row">
          <button
            [routerLink]="['/auth/login']"
            mat-raised-button
            color="accent"
          >
            {{ t('loginLink') }}
          </button>
        </div>
      </mat-card-actions>
    </mat-card>
  </ng-container>
</ng-template>
