<div class="register-page flex-center">
  <mat-card class="register-card">
    <mat-card-header>
      <mat-card-title>
        <mat-icon>person_add</mat-icon>
        {{ 'auth.register.title' | transloco }}
      </mat-card-title>
    </mat-card-header>

    <form
      [formGroup]="signupForm"
      (ngSubmit)="onSubmit($event)"
      class="register-form"
    >
      <!-- First Name Field -->
      <mat-form-field appearance="outline" class="form-field">
        <mat-label>{{ 'auth.register.firstNameLabel' | transloco }}</mat-label>
        <input
          matInput
          type="text"
          formControlName="firstName"
          [placeholder]="'auth.register.firstNamePlaceholder' | transloco"
          required
        />
        <mat-error *ngIf="signupForm.get('firstName')?.errors?.['required']">
          {{ 'auth.register.firstNameRequired' | transloco }}
        </mat-error>
        <mat-error *ngIf="signupForm.get('firstName')?.errors?.['pattern']">
          {{ 'auth.register.onlyLettersAllowed' | transloco }}
        </mat-error>
        <mat-error *ngIf="signupForm.get('firstName')?.errors?.['maxlength']">
          {{ 'auth.register.maxLength' | transloco }}
        </mat-error>
      </mat-form-field>

      <!-- Last Name Field -->
      <mat-form-field appearance="outline" class="form-field">
        <mat-label>{{ 'auth.register.lastNameLabel' | transloco }}</mat-label>
        <input
          matInput
          type="text"
          formControlName="lastName"
          [placeholder]="'auth.register.lastNamePlaceholder' | transloco"
          required
        />
        <mat-error *ngIf="signupForm.get('lastName')?.errors?.['required']">
          {{ 'auth.register.lastNameRequired' | transloco }}
        </mat-error>
        <mat-error *ngIf="signupForm.get('lastName')?.errors?.['pattern']">
          {{ 'auth.register.onlyLettersAllowed' | transloco }}
        </mat-error>
        <mat-error *ngIf="signupForm.get('lastName')?.errors?.['maxlength']">
          {{ 'auth.register.maxLength' | transloco }}
        </mat-error>
      </mat-form-field>

      <!-- Email Field -->
      <mat-form-field appearance="outline" class="form-field">
        <mat-label>{{ 'auth.register.emailLabel' | transloco }}</mat-label>
        <input
          matInput
          type="email"
          formControlName="email"
          [placeholder]="'auth.register.emailPlaceholder' | transloco"
          autocomplete="username email"
          required
        />
        <mat-error
          *ngIf="signupForm.get('email')?.errors?.['required'] || signupForm.get('email')?.errors?.['email']"
        >
          {{ 'auth.register.errorEmailInvalid' | transloco }}
        </mat-error>
      </mat-form-field>

      <!-- Password Field -->
      <mat-form-field appearance="outline" class="form-field">
        <mat-label>{{ 'auth.register.passwordLabel' | transloco }}</mat-label>
        <input
          matInput
          [type]="hide ? 'password' : 'text'"
          formControlName="password"
          [placeholder]="'auth.register.passwordPlaceholder' | transloco"
          autocomplete="new-password"
          required
        />
        <button
          mat-icon-button
          matSuffix
          type="button"
          (click)="hide = !hide"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide"
        >
          <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>
        <mat-error *ngIf="signupForm.get('password')?.errors?.['required']">
          {{ 'auth.register.errorPasswordIsRequired' | transloco }}
        </mat-error>
        <mat-error *ngIf="signupForm.get('password')?.errors?.['minlength']">
          {{ 'auth.register.errorMinValue' | transloco }}
        </mat-error>
        <mat-error *ngIf="signupForm.get('password')?.errors?.['maxlength']">
          {{ 'auth.register.errorMaxValue' | transloco }}
        </mat-error>
        <mat-error
          *ngIf="signupForm.get('password')?.errors?.['passwordStrength']"
        >
          {{ 'auth.register.errorPasswordStrength' | transloco }}
        </mat-error>
      </mat-form-field>

      <!-- Confirm Password Field -->
      <mat-form-field appearance="outline" class="form-field">
        <mat-label>{{
          'auth.register.confirmPasswordLabel' | transloco
        }}</mat-label>
        <input
          matInput
          [type]="confirmHide ? 'password' : 'text'"
          formControlName="confirmPassword"
          [placeholder]="'auth.register.confirmPasswordPlaceholder' | transloco"
          autocomplete="new-password"
          required
        />
        <button
          mat-icon-button
          matSuffix
          type="button"
          (click)="confirmHide = !confirmHide"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="confirmHide"
        >
          <mat-icon>{{
            confirmHide ? 'visibility_off' : 'visibility'
          }}</mat-icon>
        </button>
        <mat-error
          *ngIf="signupForm.get('confirmPassword')?.errors?.['required']"
        >
          {{ 'auth.register.errorPasswordIsRequired' | transloco }}
        </mat-error>
        <mat-error
          *ngIf="signupForm.get('confirmPassword')?.errors?.['passwordMatch']"
        >
          {{ 'auth.register.errorNotMatch' | transloco }}
        </mat-error>
      </mat-form-field>

      <!-- Admin Toggle -->
      <mat-slide-toggle formControlName="isMasjidAdmin" class="form-field">
        {{ 'auth.register.registerAsMasjidAdmin' | transloco }}
      </mat-slide-toggle>

      <div class="admin-info" *ngIf="signupForm.get('isMasjidAdmin')?.value">
        <mat-icon>info</mat-icon>
        <span>{{ 'auth.register.masjidAdminInfo' | transloco }}</span>
      </div>

      <!-- Error Messages -->
      <div class="error-message" [class.show]="backendError$ | async" *ngIf="backendError$ | async as error">
        <mat-icon class="error-icon">error_outline</mat-icon>
        {{ error }}
      </div>

      <!-- Submit Button -->
      <button
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="signupForm.invalid || (isSubmitting$ | async)"
      >
        {{
          (isSubmitting$ | async)
            ? ('login.loggingIn' | transloco)
            : ('auth.register.submitLabel' | transloco)
        }}
      </button>

      <!-- Links -->
      <div class="form-links">
        <a routerLink="/auth/login">
          {{ 'auth.register.alreadyLinkLabel' | transloco }}
          {{ 'auth.register.loginLink' | transloco }}
        </a>
      </div>
    </form>
  </mat-card>
</div>
