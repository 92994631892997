import { Injectable } from '@angular/core'
import { ComponentStore } from '@ngrx/component-store'
import { ProfileState } from '../../interfaces/profile.interface'

export const DefaultAvatarPath = 'assets/images/profile-avatar.svg'

export const initialState: ProfileState = {
    isSubmittingUpload: false,
    isSubmittingSave: false,
    isSubmittingAddressSave: false,
    isSubmittingDelete: false,
    isSubmittingPasswordSave: false,
    showUploadButton: false,
    errorMessage: null,
    avatarInfo: {
        avatarUrl: DefaultAvatarPath,
        hasAvatar: false,
    },
};

@Injectable()
export class ProfileStore extends ComponentStore<ProfileState> {
  // Selectors
  readonly isSubmittingUpload$ = this.select(state => state.isSubmittingUpload);
  readonly isSubmittingSave$ = this.select(state => state.isSubmittingSave);
  readonly isSubmittingAddressSave$ = this.select(state => state.isSubmittingAddressSave);
  readonly isSubmittingDelete$ = this.select(state => state.isSubmittingDelete);
  readonly isSubmittingPasswordSave$ = this.select(state => state.isSubmittingPasswordSave);
  readonly showUploadButton$ = this.select(state => state.showUploadButton);
  readonly errorMessage$ = this.select(state => state.errorMessage);
  readonly avatarInfo$ = this.select(state => state.avatarInfo);

  constructor() {
    super(initialState);
  }

  // Updaters
  readonly setShowUploadButton = this.updater(
    (state, show: boolean) => ({ ...state, showUploadButton: show })
  );

  readonly setError = this.updater(
    (state, errorMessage: string | null) => ({ 
      ...state,
      errorMessage,
      isSubmittingUpload: false,
      isSubmittingSave: false,
      isSubmittingAddressSave: false,
      isSubmittingDelete: false,
      isSubmittingPasswordSave: false
    })
  );

  readonly setAvatarInfo = this.updater(
    (state, avatarInfo: { avatarUrl: string; hasAvatar: boolean }) => ({
      ...state,
      avatarInfo
    })
  );

  readonly setSubmitting = this.updater(
    (state, { type, isSubmitting }: { type: 'upload' | 'save' | 'address' | 'delete' | 'password', isSubmitting: boolean }) => {
      switch (type) {
        case 'upload':
          return { ...state, isSubmittingUpload: isSubmitting };
        case 'save':
          return { ...state, isSubmittingSave: isSubmitting };
        case 'address':
          return { ...state, isSubmittingAddressSave: isSubmitting };
        case 'delete':
          return { ...state, isSubmittingDelete: isSubmitting };
        case 'password':
          return { ...state, isSubmittingPasswordSave: isSubmitting };
        default:
          return state;
      }
    }
  );
}
