import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class PasswordValidators {
    static match(controlName: string, matchingControlName: string): ValidatorFn {
        return (formGroup: AbstractControl): ValidationErrors | null => {
            const control = formGroup.get(controlName);
            const matchingControl = formGroup.get(matchingControlName);

            if (!control || !matchingControl) {
                return null;
            }

            if (matchingControl.errors && !matchingControl.errors['passwordMatch']) {
                return null;
            }

            if (control.value !== matchingControl.value) {
                matchingControl.setErrors({ passwordMatch: true });
                return { passwordMatch: true };
            } else {
                matchingControl.setErrors(null);
                return null;
            }
        };
    }

    static strength(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;

            if (!value) {
                return null;
            }

            const hasUpperCase = /[A-Z]+/.test(value);
            const hasLowerCase = /[a-z]+/.test(value);
            const hasNumeric = /[0-9]+/.test(value);
            const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]+/.test(value);

            const passwordValid = hasUpperCase && hasLowerCase && hasNumeric && hasSpecialChar;

            return !passwordValid ? { passwordStrength: true } : null;
        };
    }

    static minMaxLength(min: number = 8, max: number = 32): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;

            if (!value) {
                return null;
            }

            if (value.length < min) {
                return { minlength: { requiredLength: min, actualLength: value.length } };
            }

            if (value.length > max) {
                return { maxlength: { requiredLength: max, actualLength: value.length } };
            }

            return null;
        };
    }
} 