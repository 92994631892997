import { Component } from '@angular/core'
import {
    FormBuilder,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms'
import { provideComponentStore } from '@ngrx/component-store'
import { Observable } from 'rxjs'
import { ResetStore } from '../../store/states/reset.state'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { MatCardModule } from '@angular/material/card'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { TranslocoModule } from '@ngneat/transloco'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'

@Component({
    selector: 'app-reset',
    templateUrl: './reset.component.html',
    styleUrls: ['./reset.component.scss'],
    providers: [provideComponentStore(ResetStore)],
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        TranslocoModule,
        MatIconModule,
    ],
})
export class ResetComponent {
    resetForm: FormGroup
    backendError$: Observable<string | null>
    isSubmitting$: Observable<boolean>
    emailSentSuccessfully$: Observable<boolean>
    constructor(private _fb: FormBuilder, private componentStore: ResetStore) {
        this.resetForm = this._fb.group({
            email: ['', [Validators.required, Validators.email]],
        })
        this.backendError$ = this.componentStore.backendError$
        this.isSubmitting$ = this.componentStore.isSubmitting$
        this.emailSentSuccessfully$ = this.componentStore.emailSent$

        // Clear error when email changes
        this.resetForm.get('email')?.valueChanges.subscribe(() => {
            this.clearError();
        });
    }

    onSubmit(e: any): void {
        e.preventDefault()

        if (this.resetForm.invalid) {
            return
        }

        // Clear any existing error before submitting
        this.clearError();

        this.componentStore.sendResetEmail(this.resetForm.value.email)
    }

    clearError(): void {
        this.componentStore.setState(state => ({
            ...state,
            backendError: null
        }));
    }

    get email() {
        return this.resetForm.get('email')
    }
}
