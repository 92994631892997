import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { User } from '../../interfaces/user.interface';

export const ProfileActions = createActionGroup({
  source: 'Profile',
  events: {
    // Name update actions
    'Update Name': props<{ firstName: string; lastName: string }>(),
    'Update Name Success': emptyProps(),
    'Update Name Failure': props<{ error: string }>(),

    // Phone update actions
    'Update Phone': props<{ phone: string; password: string }>(),
    'Update Phone Success': emptyProps(),
    'Update Phone Failure': props<{ error: string }>(),

    // Address update actions
    'Update Address': props<{ userID: string; address: any }>(),
    'Update Address Success': emptyProps(),
    'Update Address Failure': props<{ error: string }>(),

    // Password update actions
    'Update Password': props<{ currentPassword: string; newPassword: string }>(),
    'Update Password Success': emptyProps(),
    'Update Password Failure': props<{ error: string }>(),

    // Avatar actions
    'Upload Avatar': props<{
      user: User;
      userID: string;
      base64Data: string;
      fileName: string;
      fileType: string;
    }>(),
    'Upload Avatar Success': props<{ avatarUrl: string }>(),
    'Upload Avatar Failure': props<{ error: string }>(),

    'Delete Avatar': props<{ user: User }>(),
    'Delete Avatar Success': emptyProps(),
    'Delete Avatar Failure': props<{ error: string }>(),

    // Avatar info actions
    'Set Avatar Info': props<{ avatarUrl: string; hasAvatar: boolean }>(),
    'Set Show Upload Button': props<{ show: boolean }>(),
    'Set Error': props<{ error: string | null }>()
  }
});
